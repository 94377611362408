<template>
	<r-data-list
		:headdata="headdata"
		:listdata="filteredData"
		:opt="opts"
		v-if="loaded"
		:searchfilter="searchfilter"
		v-model:qu="q"
		><template v-slot:nodatain>
			<r-select
				v-model="catid"
				label="Kategoriler"
				:data="catList.data"
				txt="title"
				val="id"
				width="25%"
				@change="openSubList"
				style="color: rgb(17, 17, 17)"
			>
				<template v-slot:itemtxt="{ data }">
					{{ data.id + '_' + data.title }}
				</template>
			</r-select>
			<r-select
				:disabled="catid === null"
				v-model="subcatid"
				label="Alt Kategoriler"
				:data="subCatList.data"
				txt="title"
				val="id"
				width="25%"
				style="color: rgb(17, 17, 17)"
			/>
		</template>
		<template v-slot:titleextrabuttons>
			<r-select
				v-model="catid"
				label="Kategoriler"
				:data="catList.data"
				txt="title"
				val="id"
				width="30%"
				@change="openSubList"
			>
				<template v-slot:itemtxt="{ data }">
					{{ data.id + '_' + data.title }}
				</template>
			</r-select>
			<r-select
				:disabled="catid === null"
				v-model="subcatid"
				label="Alt Kategoriler"
				:data="subCatList.data"
				txt="title"
				val="id"
				width="30%"
			>
				<template v-slot:itemtxt="{ data }">
					{{ data.id + '_' + data.title }}
				</template>
			</r-select>
			<r-input v-model="qs" label="Soruya Git" width="15%" />
		</template>
		<template v-slot:col5="{ datarow }">
			{{ datarow.isCase ? 'Evet' : 'Hayır' }}
		</template>
		<template v-slot:col11="{ datarow }">
			<span
				class="r-check-ok small"
				v-if="datarow.isReferenced === true"
			></span>
			<span class="r-check-no small" v-else></span>
		</template>
	</r-data-list>

	<r-panel
		title="Yeni Soru"
		v-if="newPanel"
		v-model:isactive="newPanel"
		classes="r-flx-dw-80"
		width="50%"
		pos="center"
	>
		<template v-slot:titlebuttons>
			<r-btn bgcolor="#15880d" @click="saveNew" :disabled="canSave"
				>Kaydet</r-btn
			>
		</template>
		<r-flex>
			<r-select
				v-model="newData.categoryId"
				label="Kategoriler"
				:data="catList.data"
				txt="title"
				val="id"
				width="47%"
				@change="openNewSubList"
			/>
			<r-select
				:disabled="
					newData.categoryId === undefined || newData.categoryId === null
				"
				v-model="newData.subCategoryId"
				@change="newSubCatChange"
				label="Alt Kategoriler"
				:data="subNewCatList.data"
				txt="title"
				val="id"
				width="47%"
			/>
		</r-flex>
		<r-flex v-if="newData.subCategoryId">
			<r-flex>
				<r-select
					:disabled="
						newData.categoryId === undefined || newData.categoryId === null
					"
					v-model="newData.month"
					label="Ay"
					:data="monthList.data"
					txt="monthName"
					val="id"
					width="47%"
				/><r-input v-model="newData.year" label="Yıl" width="47%" />
			</r-flex>
			<r-flex class="r-mb-16"
				><r-input
					v-model="newData.number"
					label="Soru No"
					width="31%"
				/><r-input
					v-model="newData.examNumber"
					label="S. Soru No"
					width="31%"
				/>
			</r-flex>
			<r-flex>
				<r-checkbox v-model="newData.isCase" label="Vaka Sorusu" />
				<template v-if="newData.isCase">
					<r-flex class="r-mb-16"><h4>Vaka</h4></r-flex>
					<vue-editor
						v-model="newData.case"
						label="Vaka"
						@ImageAdded="imageAddedEditor"
						@ImagePasted="imagePastedEditor"
						:useCustomImageHandler="true"
						htmlBtn
					></vue-editor
				></template>
			</r-flex>
			<r-flex>
				<r-flex class="r-mb-16"><h4>Soru</h4></r-flex>
				<vue-editor
					v-model="newData.detail"
					@ImageAdded="imageAddedEditor"
					@ImagePasted="imagePastedEditor"
					:useCustomImageHandler="true"
					htmlBtn
				/>
			</r-flex>
			<r-flex>
				<r-flex
					flx="12"
					class="r-pr-32"
					v-if="newData.choiceList && newData.choiceList.length !== 5"
					><r-flex class="r-mb-16"><h4>Şıklar</h4></r-flex
					><r-textarea v-model="choisesTxt" label="Şıklar" rows="7"
				/></r-flex>
				<r-flex flx="12"
					><r-flex v-if="newData.choiceList && newData.choiceList.length > 0">
						<template v-for="(choice, i) in newData.choiceList" :key="i">
							<r-flex class="r-mb-8"
								><strong>{{
									store.state.choices[i] + ' Şıkkı'
								}}</strong></r-flex
							>
							<vue-editor
								height="100px"
								:editorToolbar="minToolbar"
								v-model="newData.choiceList[i]"
								@ImageAdded="imageAddedEditor"
								@ImagePasted="imagePastedEditor"
								:useCustomImageHandler="true"
								htmlBtn
							/>
						</template> </r-flex
				></r-flex>
			</r-flex>
			<r-flex>
				<r-flex class="r-mb-16"><h4>Açıklama</h4></r-flex>
				<vue-editor
					v-model="newData.description"
					label="Açıklama"
					@ImageAdded="imageAddedEditor"
					@ImagePasted="imagePastedEditor"
					:useCustomImageHandler="true"
					htmlBtn
				></vue-editor>
			</r-flex>
			<r-flex
				><r-select
					v-model="newData.answer"
					label="Cevap Şıkkı"
					:data="store.state.choices"
					width="30%"
			/></r-flex>
			<r-flex>
				<r-btn bgcolor="#15880d" @click="saveNew" :disabled="canSave"
					>Kaydet</r-btn
				>
			</r-flex>
		</r-flex>
	</r-panel>
</template>
<script>
import { onBeforeMount, ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { trToUpper, mxDate } from '@/mix/global'
export default {
	setup() {
		const store = useStore()
		const listData = ref({})
		const catList = ref({})
		const subCatList = ref({})
		const subNewCatList = ref({})
		const monthList = ref({})
		const catid = ref(null)
		const subcatid = ref(0)
		const newPanel = ref(false)
		const newData = ref({})
		const loaded = ref(false)
		const q = ref('')
		const qs = ref('')
		const choisesTxt = ref('')
		const searchfilter = v => {
			return (
				trToUpper(v.description).includes(trToUpper(q.value)) ||
				trToUpper(v.detail).includes(trToUpper(q.value)) ||
				trToUpper(v.case).includes(trToUpper(q.value)) ||
				trToUpper(v.choiceList).includes(trToUpper(q.value)) ||
				trToUpper(v.number).includes(trToUpper(q.value)) ||
				trToUpper(v.examNumber).includes(trToUpper(q.value))
			)
		}
		const goquestionfilter = v => {
			return (				
				trToUpper(v.examNumber).includes(trToUpper(qs.value))
			)
		}
		const filteredData = computed(() => {
			if (listData.value.data) {
				listData.value.data.sort((a, b) => {
					return parseInt(b.examNumber) - parseInt(a.examNumber)
				})
				return listData.value.data.filter(goquestionfilter)
			} else return []
		})
		const minToolbar = ref({
			container: [
				['bold', 'italic', 'underline'],
				[
					{ align: '' },
					{ align: 'center' },
					{ align: 'right' },
					{ align: 'justify' },
				],
				[{ color: [] }, { background: [] }],
				[{ script: 'sub' }, { script: 'super' }],
				['image'],
				['clean'],
			],
		})
		const headdata = computed(() => {
			return [
				{
					title: 'S. No',
					style: { width: '5%', justifyContent: 'center' },
					key: 'number',
				},
				{
					title: 'S. S. No',
					style: { width: '5%', justifyContent: 'center' },
					key: 'examNumber',
				},
				{
					title: 'Ay',
					style: { width: '6%', justifyContent: 'center' },
					key: 'month',
					cval: v => {
						let a = monthList.value.data.filter(d => {
							return d.id === v.month
						})[0]
						return a ? a.monthName : ''
					},
				},
				{
					title: 'Yıl',
					style: { width: '5%', justifyContent: 'center' },
					key: 'year',
				},
				{
					title: 'Vaka mı?',
					style: { width: '4%', justifyContent: 'center' },
					key: 'isCase',
				},
				{
					title: 'Vaka',
					style: { width: '14%' },
					key: 'case',
					html: true,
				},
				{
					title: 'Soru',
					style: { width: '15%' },
					key: 'detail',
					html: true,
				},
				{
					title: 'Şıklar',
					style: { width: '13%' },
					key: 'choiceList',
					html: true,
					cval: v => {
						var str = ''
						v.choiceList.forEach((d, i) => {
							if (d.startsWith('<p>')) {
								str += `${d.slice(0, 3)}<strong>${
									store.state.choices[i]
								}) </strong>${d.slice(3)}`
							} else {
								str += '<strong>' + store.state.choices[i] + ': </strong>' + d
								if (i !== v.choiceList.length - 1) str += '<br>'
							}
						})
						return str
					},
				},
				{
					title: 'Açıklama',
					style: { width: '14%' },
					key: 'description',
					html: true,
					cval: v => {
						var str = '<strong>Cevap:</strong> ' + v.answer + '<br>'
						str += v.description
						return str
					},
				},
				{
					title: 'Tarih',
					style: { width: '7%' },
					key: 'createDate',
					html: true,
					cval: v => {
						return mxDate('dd-mm-yy-t', v.createDate, '.')
					},
				},
				{
					title: 'Referanslı mı?',
					style: { width: '6%', justifyContent: 'center' },
					key: 'isReferenced',
				},
			]
		})
		const opts = ref({
			title: 'Sorular',
			nodatatxt: () => {
				return catid.value === null
					? 'Lütfen kategori seçiniz'
					: filteredData.value && filteredData.value.length === 0
					? 'Sonuç bulunamadı'
					: ''
			},
			buttons: {
				style: { width: '6%' },
				edit: {
					show: true,
					func: d => {
						editFunc(d)
					},
				},
				delete: {
					show: true,
					func: d => {
						deleteFunc(d)
					},
				},
				add: {
					show: true,
					txt: 'Yeni Soru',
					type: 'out',
					func: d => {
						addFunc(d)
					},
				},
			},
		})
		const canSave = computed(() => {
			if (newData.value.isCase) {
				if (newData.value.case === undefined || newData.value.case === '')
					return true
			}
			if (
				newData.value.categoryId &&
				newData.value.subCategoryId &&
				newData.value.month &&
				newData.value.year &&
				newData.value.year !== '' &&
				newData.value.number &&
				newData.value.number !== '' &&
				newData.value.examNumber &&
				newData.value.examNumber !== '' &&
				newData.value.choiceList &&
				newData.value.choiceList.length === 5
			)
				return false
			else return true
		})
		
		const imageAddedEditor = async (f, e, cl, ru) => {
			var rndm = parseInt(Math.random() * 10000000)
			var reader = new FileReader()
			reader.readAsDataURL(f)
			reader.onload = async () => {
				let s64file = reader.result.replace('data:image/jpeg;base64,', '')
				var ext = f.name.split('.').pop()
				var saveData = [
					{
						ImageString: s64file,
						Location:
							'soruresimleri/' +
							store.state.usertype +
							'/' +
							store.state.workFolder +
							'/' +
							newData.value.categoryId +
							'/' +
							f.name.split('.').shift() +
							'_' +
							rndm +
							'.' +
							ext,
					},
				]
				let res = await store.dispatch('postDatas', {
					api: 'references/saveimages',
					data: saveData,
				})
				if (res.result) {
					let ind = (e.getSelection() || {}).index || e.getLength()
					if (ind < 0) ind = e.getLength()
					e.insertEmbed(
						ind,
						'image',
						'https://referans.tusdata.com/soruresimleri/' +
							store.state.usertype +
							'/' +
							store.state.workFolder +
							'/' +
							newData.value.categoryId +
							'/' +
							f.name.split('.').shift() +
							'_' +
							rndm +
							'.' +
							ext
					)
					ru()
					let htm = e.root.innerHTML
					e.root.innerHTML = htm
					setTimeout(() => {
						e.setSelection(ind+1)
					},1)
				}
			}
		}
		const imagePastedEditor = async (dataUrl, e, cl, type) => {
			var rndm = parseInt(Math.random() * 10000000)
			let s64file = dataUrl.replace('data:' + type + ';base64,', '')
			var ftype = type.includes('png') ? 'png' : 'jpg'
			var res = {}
			var intpaste = setInterval(async () => {
				if (s64file !== '') {
					clearInterval(intpaste)
					var saveData = [
						{
							ImageString: s64file,
							Location:
								'soruresimleri/' +
								store.state.usertype +
								'/' +
								store.state.workFolder +
								'/' +
								newData.value.categoryId +
								'/' +
								'soru_resim_' +
								newData.value.number +
								'_' +
								rndm +
								'.' +
								ftype,
						},
					]
					res = await store.dispatch('postDatas', {
						api: 'references/saveimages',
						data: saveData,
					})
				}
			}, 50)

			var intimg = setInterval(() => {
				if (res.result) {
					clearInterval(intimg)
					let ind = (e.getSelection() || {}).index || e.getLength()
					if (ind < 0) ind = e.getLength()
					e.insertEmbed(
						ind,
						'image',
						'https://referans.tusdata.com/soruresimleri/' +
							store.state.usertype +
							'/' +
							store.state.workFolder +
							'/' +
							newData.value.categoryId +
							'/' +
							'soru_resim_' +
							newData.value.number +
							'_' +
							rndm +
							'.' +
							ftype,
						'user'
					)
					let htm = e.root.innerHTML
					e.root.innerHTML = htm
					setTimeout(() => {
						e.setSelection(ind+1)
					},1)
				}
			}, 100)
		}
		const getList = async (id, sid) => {
			let list = await store.dispatch('getDatas', {
				api: 'questions/GetQuestionsByCategory',
				params: {
					categoryid: id,
					subcatid: sid || 0,
					page: 1,
					size: 100,
					order: 1,
					workmonth: store.state.workMonth,
					workyear: store.state.workYear,
				},
			})
			return list
		}
		const getCatList = async () => {
			let list = await store.dispatch('getDatas', {
				api: 'categories/getsubcategories',
				params: {
					pid: 0,
				},
			})

			return list
		}
		const getSubCatList = async b => {
			let list = await store.dispatch('getDatas', {
				api: 'categories/getsubcategories',
				params: {
					pid: b ? newData.value.categoryId : catid.value,
				},
			})
			return list
		}
		const getMonths = async () => {
			let list = await store.dispatch('getDatas', {
				api: 'questions/getmonths',
			})
			return list
		}
		const openSubList = async d => {
			catid.value = d.id
			subCatList.value = await getSubCatList()
			subCatList.value.data.unshift({ id: 0, title: 'Tümü' })
			subcatid.value = 0
		}
		const openNewSubList = async d => {
			newData.value.categoryId = d.id
			subNewCatList.value = await getSubCatList(true)
		}

		const addFunc = async () => {
			newData.value = {}
			choisesTxt.value = ''
			newData.value.choiceList = []
			newData.value.workyear = store.state.workYear
			newData.value.workmonth = store.state.workMonth
			newData.value.year = mxDate('yy')
			newData.value.categoryId = catid.value
			newData.value.subCategoryId = subcatid.value
			if (newData.value.subCategoryId) {
				newData.value.number = listData.value.data.length + 1
			}
			subNewCatList.value = await getSubCatList(true)
			newPanel.value = true
		}
		const editFunc = async d => {
			Object.assign(newData.value, d.row)
			subNewCatList.value = await getSubCatList(true)
			newPanel.value = true
		}
		const deleteFunc = async d => {
			await store.dispatch('postDatas', {
				api: 'questions/deletequestion',
				data: { id: parseInt(d.row.id) },
			})
			listData.value = await getList(catid.value, subcatid.value)
		}
		const saveNew = async () => {
			newData.value.case = newData.value.case || ''
			newData.value.isCase = newData.value.isCase || false
			newData.value.number = parseInt(newData.value.number)
			newData.value.examNumber = parseInt(newData.value.examNumber)
			newData.value.year = parseInt(newData.value.year)
			newData.value.month = parseInt(newData.value.month)
			newData.value.description = newData.value.description || ''
			newData.value.isActive = true
			await store.dispatch('postDatas', {
				api: 'questions/addquestion',
				data: newData.value,
			})
			listData.value = await getList(catid.value, subcatid.value)
			newPanel.value = false
		}
		const newSubCatChange = async () => {
			newData.value = Object.assign({}, newData.value)
			var data = {}
			setTimeout(async () => {
				data = await getList(
					newData.value.categoryId,
					newData.value.subCategoryId
				)
				newData.value.number = data.data.length + 1
			}, 50)
		}
		watch(
			() => choisesTxt.value,
			() => {
				let arr = choisesTxt.value.split(/A\)\s|B\)\s|C\)\s|D\)\s|E\)\s/g)
				arr.shift()
				newData.value.choiceList = arr
			}
		)
		watch(
			() => [subcatid.value, catid.value],
			async () => {
				listData.value = await getList(catid.value, subcatid.value)
			}
		)
		onBeforeMount(async () => {
			await store.dispatch('getWorkFolder')
			catList.value = await getCatList()
			monthList.value = await getMonths()
			monthList.value.data.shift()
			monthList.value.data.forEach((itm, i) => {
				itm.id = i + 1
			})
			loaded.value = true
		})
		return {
			headdata,
			opts,
			listData,
			saveNew,
			newPanel,
			openSubList,
			newData,
			loaded,
			q,
			qs,
			searchfilter,
			catList,
			catid,
			subcatid,
			subCatList,
			subNewCatList,
			openNewSubList,
			choisesTxt,
			minToolbar,
			monthList,
			canSave,
			store,
			imageAddedEditor,
			imagePastedEditor,
			newSubCatChange,
			filteredData,
		}
	},
}
</script>
